/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.component-interactive-map {
  position: relative;
}

@media (min-width: 992px) {
  .component-interactive-map {
    height: calc(100vh - 163px);
    display: flex;
    flex-direction: column-reverse;
  }
}

.component-interactive-map .loading-element,
.component-interactive-map .map-element {
  height: 100%;
}

@media (min-width: 992px) {
  .component-interactive-map .container-element {
    flex-grow: 1;
    position: relative;
  }
  .component-interactive-map .container-element > div {
    position: absolute !important;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .component-interactive-map .pane {
    background: #fafafa;
  }
}

@media (min-width: 992px) {
  .component-interactive-map .pane {
    position: absolute;
    top: 35px;
    right: 23px;
    bottom: 35px;
    max-height: calc(100% - 70px);
    max-width: 364px;
    overflow: auto;
  }
}

.component-interactive-map .pane .place-card {
  border: 1px solid #eaebeb;
  background: #fff;
  margin-bottom: 2px;
}

.component-interactive-map .pane .place-card .hgroup {
  padding: 1.8em 2.2em 2.2em;
}

.component-interactive-map .pane .place-card img {
  width: 100%;
  max-height: 30vh;
  object-fit: cover;
  object-position: center;
}

.component-interactive-map .pane .place-card .rating {
  margin-top: 0.5em;
}

.component-interactive-map .pane .place-card .rating .label {
  font-size: 1.6em;
  margin: 0 0 0 1em;
  display: inline-block;
  position: relative;
  top: -0.2em;
}

.component-interactive-map .pane .place-card .rating .outof-stars {
  background-image: url(../images/map/star-fade.png);
  display: inline-block;
  width: 120px;
  position: relative;
  height: 20px;
}

.component-interactive-map .pane .place-card .rating .outof-stars .stars {
  background-image: url(../images/map/star.png);
  height: 100%;
  display: block;
}

.component-interactive-map .pane .place-card .icon {
  width: 40px;
  height: 40px;
}

.component-interactive-map .pane .place-card .icon.icon-gym {
  background-image: url(../images/map/square-sports.svg);
}

.component-interactive-map .pane .place-card .icon.icon-gym.active {
  background-image: url(../images/map/square-sports-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-bank {
  background-image: url(../images/map/square-banks.svg);
}

.component-interactive-map .pane .place-card .icon.icon-bank.active {
  background-image: url(../images/map/square-banks-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-cafe {
  background-image: url(../images/map/square-cafes.svg);
}

.component-interactive-map .pane .place-card .icon.icon-cafe.active {
  background-image: url(../images/map/square-cafes-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-post-office {
  background-image: url(../images/map/square-post-office.svg);
}

.component-interactive-map .pane .place-card .icon.icon-post-office.active {
  background-image: url(../images/map/square-post-office-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-restaurant {
  background-image: url(../images/map/square-restaurant.svg);
}

.component-interactive-map .pane .place-card .icon.icon-restaurant.active {
  background-image: url(../images/map/square-restaurant-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-retail {
  background-image: url(../images/map/square-retail.svg);
}

.component-interactive-map .pane .place-card .icon.icon-retail.active {
  background-image: url(../images/map/square-retail-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-transport {
  background-image: url(../images/map/square-transport.svg);
}

.component-interactive-map .pane .place-card .icon.icon-transport.active {
  background-image: url(../images/map/square-transport-active.svg);
}

.component-interactive-map .pane .place-card .icon.icon-parking {
  background-image: url(../images/map/square-parking.svg);
}

.component-interactive-map .pane .place-card .icon.icon-parking.active {
  background-image: url(../images/map/square-parking-active.svg);
}

.component-interactive-map .pane .place-card .description {
  font-size: 1.6em;
  padding: 1.2em 1.4em 0.9em;
}

.component-interactive-map .pane .place-card .description p {
  margin-bottom: 5px;
}

.component-interactive-map .pane .place-card a[target=_blank]:after {
  content: '';
  background: url(../images/icons/icon-external-site--gray.svg);
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-left: 1em;
  font-size: 0.8em;
  position: relative;
  top: 0em;
}

.component-interactive-map .pane .place-card h1 {
  font-size: 1.6em;
  position: relative;
  font-family: "Roboto", sans-serif;
  padding-right: 2.5em;
}

.component-interactive-map .pane .place-card h1 .icon {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  right: 0;
  top: -0.3em;
}

.component-interactive-map .pane .place-card.is-expanded-true.has-image h1 .icon {
  top: -2.9em;
}

.component-interactive-map .pane .place-card.is-expanded-true h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: -0.4em;
}

.component-interactive-map .pane .place-card.is-expanded-true h1 .icon {
  font-size: 0.8em;
}

@media (min-width: 992px) {
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-gym {
    background-image: url(../images/map/square-sports-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-bank {
    background-image: url(../images/map/square-banks-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-cafe {
    background-image: url(../images/map/square-cafes-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-post-office {
    background-image: url(../images/map/square-post-office-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-restaurant {
    background-image: url(../images/map/square-restaurant-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-retail {
    background-image: url(../images/map/square-retail-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-transport {
    background-image: url(../images/map/square-transport-active.svg);
  }
  .component-interactive-map .pane .place-card.is-expanded-true h1 .icon.icon-parking {
    background-image: url(../images/map/square-parking-active.svg);
  }
}

.component-interactive-map .pane .place-card.is-expanded-true .hgroup {
  border-bottom: 1px solid #eaebeb;
  padding: 1.8em 2.2em 1.5em;
}

.component-interactive-map p.results,
.component-interactive-map div.results {
  font-size: 1.6em;
  margin: 1.8em 1.4em 1.5em;
}

.component-interactive-map .filter-bar {
  color: white;
  justify-content: center;
  padding: 2em 2em 2.6em;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  border-top: 1px solid white;
}

.component-interactive-map .filter-bar.introScreenfalse {
  background: #8c7253 !important;
}

@media (max-width: 1199px) {
  .component-interactive-map .filter-bar {
    background: #8c7253 !important;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-100%);
    transition: transform 1s ease-out;
    z-index: 100;
  }
  .component-interactive-map .filter-bar.is-expanded-true {
    transform: translateY(0);
  }
  .component-interactive-map .filter-bar .align {
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  .component-interactive-map .filter-bar .align h1 {
    font-size: 3.2em;
    margin: 0 0 0.7em;
  }
  .component-interactive-map .filter-bar .align .checkboxes {
    display: inline-block;
    text-align: left;
  }
  .component-interactive-map .filter-bar .align .checkboxes .field {
    margin: 0 0 2.6em;
  }
  .component-interactive-map .filter-bar .align .btn {
    font-size: 1.6em;
    text-transform: uppercase;
    color: white;
    font-family: "Oswald", sans-serif;
  }
  .component-interactive-map .filter-bar .align .btn:after {
    background: #fff;
  }
}

@media (min-width: 992px) {
  .component-interactive-map .filter-bar .align {
    display: flex;
    justify-content: center;
    width: 95%;
  }
}

.component-interactive-map .filter-bar h1 {
  font-size: 2.4em;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: white;
  font-weight: 400;
}

@media (min-width: 992px) {
  .component-interactive-map .filter-bar h1 {
    margin-right: 2.1em;
  }
}

@media (max-width: 1199px) {
  .component-interactive-map .filter-bar h1 {
    text-align: center;
  }
}

.component-interactive-map .filter-bar .checkboxes {
  padding-top: 3px;
}

@media (min-width: 992px) {
  .component-interactive-map .filter-bar .checkboxes {
    display: flex;
  }
}

.component-interactive-map .filter-bar .checkboxes .field {
  margin-right: 2em;
  margin-left: 2em;
}

.component-interactive-map .filter-bar .checkboxes label {
  font-size: 2em;
  cursor: pointer;
  opacity: 0.4;
  display: block;
}

.component-interactive-map .filter-bar .checkboxes label:before {
  width: 1.3em;
  height: 1.3em;
  border: 2px solid white;
  content: '';
  display: inline-block;
  position: relative;
  margin-right: 0.5em;
  vertical-align: bottom;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.component-interactive-map .filter-bar .checkboxes input {
  position: absolute;
  opacity: 0;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label {
  opacity: 1;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label:before {
  background-image: url(../images/icons/icon-tick.svg);
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.bank:before {
  background-color: #8977aa;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.cafe:before {
  background-color: #7ba4db;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.post-office:before {
  background-color: #f99a53;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.restaurant:before {
  background-color: #6c8a78;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.retail:before {
  background-color: #ef9ed4;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.gym:before {
  background-color: #fcd652;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.transport:before {
  background-color: #717C89;
}

.component-interactive-map .filter-bar .checkboxes input:checked + label.parking:before {
  background-color: #ee2737;
}

@media (min-width: 992px) {
  .component-interactive-map.intro-screen-true .filter-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #007b59;
  }
  .component-interactive-map.intro-screen-true .filter-bar:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }
  .component-interactive-map.intro-screen-true .filter-bar .align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    display: block;
    text-align: center;
  }
  .component-interactive-map.intro-screen-true .filter-bar h1 {
    font-size: 5.2em;
    margin: 0 0 0.7em;
  }
  .component-interactive-map.intro-screen-true .filter-bar .checkboxes {
    flex-wrap: wrap;
    text-align: left;
  }
  .component-interactive-map.intro-screen-true .filter-bar .checkboxes .field {
    flex-basis: 50.6%;
    flex-grow: 1;
    margin: 0 0 2.6em;
    width: 22%;
  }
  .component-interactive-map.intro-screen-true .filter-bar .checkboxes .field:nth-of-type(2n) {
    flex-basis: 24%;
  }
  .component-interactive-map.intro-screen-true .filter-bar .checkboxes .field label {
    font-size: 2.4em;
    white-space: nowrap;
    opacity: 1;
  }
  .component-interactive-map.intro-screen-true .filter-bar .checkboxes .field label:before {
    margin-right: 1em;
  }
  .component-interactive-map.intro-screen-true .filter-bar .btn {
    font-size: 1.6em;
    text-transform: uppercase;
    color: white;
    font-family: "Oswald", sans-serif;
  }
  .component-interactive-map.intro-screen-true .filter-bar .btn:after {
    background: #fff;
  }
}

.filter__refine-btn.btn {
  display: flex;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0;
  border-color: #dbdbdb;
  background-color: white;
  color: #55565b;
  font-family: "Roboto", sans-serif;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 3.22222em;
  text-align: center;
  text-transform: none;
  padding: .375rem .75rem;
}

.filter__refine-icon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 1.2rem;
  margin-right: 1rem;
}

.filter__refine-icon > div {
  display: block;
  height: 2px;
  background-color: #55565b;
}

.filter__refine-icon > div:first-child {
  width: 1.8rem;
}

.filter__refine-icon > div:nth-child(2) {
  width: 1rem;
}

.filter__refine-icon > div:nth-child(3) {
  width: 0.4rem;
}
